import * as React from "react";

import { DisabledLink } from "@impero-com/ui";
import { Link } from "react-router-dom";

import { t } from "utils/i18n";

type LinkType = { href: string } | { to: string };

function isHref(linkType: LinkType): linkType is { href: string } {
    return (linkType as { href: string }).href !== undefined;
}

type ProtectedLinkProps = {
    children: React.ReactNode,
    hasAccess: boolean,
    className?: string,
} & LinkType;

// Will check `hasAccess` to determine if a disabled link should be rendered or not.
// Using `to` will navigate using react-router's `Link` component, using `href` will render a native `a` anchor.
// When trying to link to a legacy route, always use `href` as `react-router` doesn't know about them.
class ProtectedLink extends React.PureComponent<ProtectedLinkProps> {
    render = () => {
        const { children, hasAccess, className } = this.props;
        let linkTypeComponent: React.ReactNode;
        if (isHref(this.props)) {
            linkTypeComponent = <a href={this.props.href}>{children}</a>;
        } else {
            linkTypeComponent = <Link to={this.props.to}>{children}</Link>;
        }
        return !hasAccess ? (
            <DisabledLink className={className} reason={t("InsufficientAccess")}>{children}</DisabledLink>
        ) : linkTypeComponent;
    };
}

export { ProtectedLink };
