import * as React from "react";

import { DownOutlined } from "@ant-design/icons";
import { Button } from "@impero-com/ui";
import { Dropdown } from "antd";
import { Link } from "react-router-dom";

import { BreadcrumbSpec } from "componentTypes/breadcrumb";
import { WebRoute } from "routes";
import { setFlashRedirectToCurrentLocation } from "utils/flash";
import { Meta } from "utils/header";
import { t } from "utils/i18n";
import { orgLogout } from "utils/logout";
import { useOrgCookie } from "utils/orgCookie";
import { useOrgUserProfileCookie } from "utils/profileCookie";


import { HeaderTitle } from "./headerTitle";
import theme from "./theme.module.scss";

interface OrgProfileSectionProps {
    routerLinks?: boolean,
}
const OrgProfileSection = ({
    routerLinks,
}: OrgProfileSectionProps) => {
    const orgCookie = useOrgCookie();
    const orgProfileCookie = useOrgUserProfileCookie();

    const handleLogin = () => {
        setFlashRedirectToCurrentLocation();
        window.location.assign("/");
    };
    const nbsp = "\xA0";
    const orgName = orgCookie!.name.replace(/ /g, nbsp);
    if (!orgProfileCookie) {
        return (
            <div className={theme.profile}>
                <div className={theme.profileIdentification}>
                    <span className={theme.organization}>{orgName}</span>
                    <Button id="login" type="link" className={theme.logInButton} onClick={handleLogin}>{t("Login_MenuTitle")}</Button>
                </div>
            </div>
        );
    }

    const menuItems = [
        {
            key: 0,
            label: routerLinks === undefined || routerLinks ?
                <Link to={WebRoute.PROFILE}>{t("Menu_MyProfile")}</Link> :
                <a href={WebRoute.PROFILE}>{t("Menu_MyProfile")}</a>,
        },
        {
            key: 1,
            label: <Button id="logout" type="link" className={theme.menuButton} onClick={orgLogout}>{t("Logoff_MenuTitle")}</Button>,
        },
    ];
    const { firstName, lastName } = orgProfileCookie;
    const userName = `${firstName} ${lastName}`.replace(/ +/g, nbsp);
    return (
        <Dropdown menu={{ items: menuItems }} className={theme.profile} trigger={["click"]}>
            <div className={theme.profile}>
                <div className={theme.profileIdentification}>
                    <span className={theme.organization}>{orgName}</span>
                    <strong>{userName}</strong>
                </div>
                <DownOutlined type="down"/>
            </div>
        </Dropdown>
    );
};


export interface ApplicationHeaderProps {
    title: string | BreadcrumbSpec,
    routerLinks?: boolean,
}
export const ApplicationHeader = ({
    title,
    routerLinks,
}: ApplicationHeaderProps) => {
    const isStringTitle = typeof title === "string";
    const pageTitle = isStringTitle ? title : title.title;

    return (
        <div className={theme.applicationHeader}>
            <Meta title={pageTitle} />
            <HeaderTitle title={title} />
            <OrgProfileSection routerLinks={routerLinks} />
        </div>
    );
};


