import * as React from "react";

import { Button } from "@impero-com/ui";
import { Card } from "antd";

import LogoBodil from "img/logo-bodil.svg";
import Logo from "img/logo.svg";
import { IMPERO_HELPDESK_URL } from "utils/help";
import { t } from "utils/i18n";
import { Svg } from "utils/svgIcon";

import theme from "./theme.module.scss";

const FormCardContainer = (props: FormCardContainerProps) => {
    const { children, rightSide, fullWidth, showLogo } = props;

    const formPageFooter = (
        <footer className={theme.formPageFooter}>
            <Svg src={LogoBodil} className={theme.formPageFooterSvg} style={{ width: "30px", height: "30px" }} />
            <p className={theme.textFooter}>{t("Footer")}</p>
            <Button id="support-button" type="link" block href={IMPERO_HELPDESK_URL} target="_blank">
                {t("LoginFlow_Support")}
            </Button>
        </footer >
    );

    const cardContent = (
        <div className={theme.cardContent}>
            {showLogo &&
                <div className={theme.cardLogo}>
                    <Svg src={Logo} style={{ width: "220px", height: "33px" }} />
                </div>
            }
            <Card bordered={false} className={theme.loginFormCard}>
                <div className={theme.loginCardWrapper}>
                    {children}
                </div>
            </Card>
        </div>
    );

    return (
        <div className={fullWidth ? theme.formWrapperFullWidth : theme.formWrapper}>
            {fullWidth ?
                <div className={theme.loginFormLeftFullWidth}>{cardContent}</div> :
                <div className={theme.loginFormLeft}>{cardContent}{formPageFooter}</div>}

            {rightSide ? rightSide : null}
        </div>
    );
};

interface FormCardContainerProps {
    children?: any,
    rightSide?: JSX.Element,
    fullWidth?: boolean,
    showLogo?: boolean,
}

export default FormCardContainer;
