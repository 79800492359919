/**
 * Takes an array of elements and a delimiter, then returns a new array with the
 * delimiter inserted between each element of the original array.
 *
 * Example:
 * ```
 *     interleaveArrayWithDelimiter([1, 2, 3], 0)
 *     => [1, 0, 2, 0, 3]
 * ```
 */
function interleaveArrayWithDelimiter<T>(
    array: Array<T>,
    delimiter: T,
): Array<T> {
    const emptyArray: Array<T> = [];
    return array.reduce((acc, item, index) => {
        if (index > 0) {
            acc.push(delimiter);
        }
        acc.push(item);
        return acc;
    }, emptyArray);
}

/**
 * Find all duplicate elements in an array.
 *
 * Example:
 * ```
 *     findDuplicates([1, 2, 3, 1, 4, 2])
 *     => [1, 2]
 * ```
 */
function findDuplicates<T>(array: Array<T>): Array<T> {
    return array.filter((item, index) => array.indexOf(item) !== index);
}

export {
    interleaveArrayWithDelimiter,
    findDuplicates,
};
