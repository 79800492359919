import * as React from "react";

import { RightOutlined } from "@ant-design/icons";
import { Dropdown, Menu, MenuProps, Tooltip, Typography } from "antd";
import { ItemType } from "rc-menu/es/interface";

import { ProtectedLink } from "components/protectedLink";
import { ActionSpec, ActionLink, ActionClick, ActionDropdownSpec, ActionDropdownItem } from "componentTypes/action";
import More from "img/more.svg";
import { interleaveArrayWithDelimiter } from "utils/array";
import { Svg } from "utils/svgIcon";


import theme from "./theme.module.scss";

class ActionMenu extends React.Component<ActionMenuProps> {
    render() {
        const { actions, id } = this.props;
        // Hack to allow usage of properties listed in https://github.com/react-component/menu#api and use an custom theme
        const extraProps = { triggerSubMenuAction: "click", theme: "impero-action-menu" } as {};
        const items: MenuProps["items"] = actions.map(this.renderAction);

        return <Menu
            id={id}
            mode="horizontal"
            className={theme.menu}
            selectable={false}
            items={items}
            {...extraProps}
            overflowedIndicator={<Svg className={theme.dots} src={More} />}
        />;
    }

    renderAction = (action: ActionSpec): ItemType => {
        if ("type" in action && action.type === "Dropdown") {
            return renderDropdown(action);
        }


        const disabled = action.status !== undefined ? action.status.type === "DISABLED" : false;
        const label = action.status !== undefined && action.status.type === "DISABLED" ?
            <Tooltip title={action.status.reason}>
                <Svg className={theme.icon} src={action.icon.svg}/>
                {action.label}
            </Tooltip>
            : <>
                <Svg className={theme.icon} src={action.icon.svg}/>
                {action.label}
            </>;
        const actionLink: { href: string} | { to: string } | undefined = (action as ActionLink).link;
        const link = actionLink ? <ProtectedLink {...actionLink} hasAccess={true}>{label}</ProtectedLink> : label;
        const onClick: (() => void) | undefined = (action as ActionClick).onClick;

        return {
            key: action.id,
            className: `${theme.menuItem} ${disabled ? theme.disabledMenuItem : ""}`,
            onClick,
            disabled,
            label: link,
        };
    };
}

function renderDropdown(action: ActionDropdownSpec): ItemType {
    const disabled = action.status !== undefined ? action.status.type === "DISABLED" : false;

    const items = action.items.map(prepareDropdownItem);
    const itemsWithDividers = addDividers(items);

    const label = <Dropdown
        disabled={disabled}
        menu={{
            items: itemsWithDividers,
            className: theme.dropdownActionMenu,
        }}
        trigger={["click"]}
    >
        <Typography.Link>
            <Svg className={theme.icon} src={action.icon.svg}/>
            {action.label}
        </Typography.Link>
    </Dropdown>;

    return {
        key: action.id,
        className: `${theme.menuItem} ${disabled ? theme.disabledMenuItem : ""}`,
        disabled,
        label,
    };
}

function prepareDropdownItem(item: ActionDropdownItem): ItemType {
    const link = item.action.link;
    return {
        key: item.key,
        className: theme.dropdownActionMenuItem,
        label:
            <a href={link.href}>
                <div className={theme.dropdownActionMenuItemContent}>
                    <div>
                        <div className={theme.dropdownActionMenuItemTitle}>
                            {item.title}
                        </div>
                        <div className={theme.dropdownActionMenuItemDescription}>
                            {item.description}
                        </div>
                    </div>
                    <RightOutlined />
                </div>
            </a>,
    };
}

function addDividers(items: Array<ItemType>): Array<ItemType> {
    const divider: ItemType = {
        type: "divider",
        className: theme.dropdownActionMenuItemDivider,
    };
    return interleaveArrayWithDelimiter(items, divider);
}

interface ActionMenuProps {
    id: string,
    actions: ActionSpec[],
}

export {
    ActionMenu,
    type ActionMenuProps,
};
