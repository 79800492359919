import React, { Key, ReactNode, useEffect } from "react";

import { Button, CardFooter, FormSubtitle, FormTitle } from "@impero-com/ui";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";

import FormCardContainer from "components/formCardContainer";
import { LoggedInHTTPError } from "errorPages/loggedInError";
import { t } from "utils/i18n";
import { useOrgCookie } from "utils/orgCookie";
import { useOrgUserProfileCookie } from "utils/profileCookie";

const isAxiosError = (error: Error | AxiosError): error is AxiosError => {
    return (error as AxiosError).isAxiosError === true;
};
const RuntimeError = () => {
    return (
        <FormCardContainer fullWidth={true}>
            <FormTitle title={t("ErrorPage_RuntimeError")} />
            <FormSubtitle subtitle={t("ErrorPage_RuntimeErrorText")} />
            <CardFooter>
                <Button
                    id="client-error-button"
                    type="link"
                    onClick={() => window.location.reload()}>
                    {t("ErrorPage_Reload")}
                </Button>
            </CardFooter>
        </FormCardContainer>
    );
};

interface ErrorBoundaryClassProps {
    children: ReactNode,
    key: Key,
}
interface ErrorBoundaryClassState {
    hasError: boolean,
    httpErrorCode: number | null,
}
class ErrorBoundaryClass extends React.Component<ErrorBoundaryClassProps, ErrorBoundaryClassState> {
    state = {
        hasError: false,
        httpErrorCode: null,
    };
    static getDerivedStateFromError(error: Error | AxiosError) {
        if (isAxiosError(error)) {
            return {
                hasError: true,
                httpErrorCode: error.response?.status ?? null,
            };
        } else {
            Sentry.captureException(error);
            return { hasError: true };
        }

    }
    componentDidCatch(error: Error): void {
        Sentry.captureException(error);
    }
    render() {
        if (!this.state.hasError) {
            return this.props.children;
        }

        if (this.state.httpErrorCode) {
            return <LoggedInHTTPError code={this.state.httpErrorCode} />;
        }

        return <RuntimeError />;
    }
}

interface ErrorBoundaryProps {
    children: React.ReactNode,
    routeKey: Key,
}
const ErrorBoundary = ({
    children,
    routeKey,
}: ErrorBoundaryProps) => {
    const orgProfileCookie = useOrgUserProfileCookie();
    const orgCookie = useOrgCookie();

    useEffect(() => {
        Sentry.setTags({
            shortName: orgCookie?.shortName,
            culture: orgProfileCookie?.culture,
        });
        Sentry.setContext("metadata", {
            userId: orgProfileCookie?.userId,
        });
    }, [orgCookie, orgProfileCookie]);

    return (
        <ErrorBoundaryClass key={routeKey}>
            {children}
        </ErrorBoundaryClass>
    );
};

export {
    ErrorBoundary,
};
