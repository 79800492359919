import React, { useState } from "react";

import parse from "html-react-parser";
import { micromark } from "micromark";
import { Options } from "micromark-util-types";


import { sanitizeMarkdown } from "./sanitize";
// styling
import theme from "./theme.module.scss";

// Convenience function to render markdown with impero default rules
function renderMarkdown(
    source: string | null | undefined,
): React.ReactNode {
    if (source === null || source === undefined || source === "") {
        return null;
    } else {
        try {
            return <div className={theme.markdown}>{renderMarkdownWithMicromark(source)}</div>;
        } catch (e) {
            const lines = source.split("\n");
            return <div className={theme.markdown}>{lines.map((line, index) => (<p key={index}>{line}</p>))}</div>;
        }
    }
}

const MICROMARK_OPTIONS: Options = {
    allowDangerousHtml: false,
    allowDangerousProtocol: false,
    extensions: [],
    htmlExtensions: [],
};

function renderMarkdownWithMicromark(markdownText: string): React.ReactNode {
    const sanitizedMarkdownText = sanitizeMarkdown({
        markdownText,
        onFailure: (msg: string) => {
            console.error(`renderMarkdown((): Failed to sanitize markdown.\nError: ${msg}\nMarkdown:\n${markdownText}`);
        },
    });
    const html = micromark(sanitizedMarkdownText, MICROMARK_OPTIONS);
    const parsedHtml: React.ReactNode = parse(html);

    // We want all the links to be opened in a new tab
    const modifiedHtml = modifyPropsByNodeType(parsedHtml, "a", { target: "_blank" });
    return modifiedHtml;
}

/**
 * Clone the element and for every descendant of the given type extend/override the props.
 * Note: we have to clone because React elements are immutable.
 */
function modifyPropsByNodeType(
    rootNode: React.ReactNode,
    targetNodeType: string,
    newProps: Record<string, string | number>
): React.ReactNode {
    if (Array.isArray(rootNode)) {
        return rootNode.map((node: React.ReactNode) => modifyPropsByNodeType(node, targetNodeType, newProps));
    }

    if (!React.isValidElement(rootNode)) {
        return rootNode; // Return non-element nodes as-is (text, null, etc.)
    }

    // If the node is of the target type, clone it with the new props
    if (rootNode.type === targetNodeType) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return React.cloneElement(rootNode, { ...rootNode.props, ...newProps });
    }

    // Recursively process children if there are any
    if (rootNode.props.children) {
        const children = React.Children.map(rootNode.props.children as React.ReactNode, (child: React.ReactNode) =>
            modifyPropsByNodeType(child, targetNodeType, newProps)
        );

        // Clone the current element with modified children
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return React.cloneElement(rootNode, { ...rootNode.props, children });
    }

    return rootNode; // Return the element as-is if no modifications are needed
}


const DEMO_SOURCE =
    '![Impero Logo](/assets/img/logo_dark.png "Impero Logo")\n' +
    "\n" +
    "# **Impero** *Live* demo\n" +
    "\n" +
    "> Stay in\n" +
    "> **CONTROL**!\n" +
    "\n" +
    "Changes are automatically rendered as you type.\n" +
    "\n" +
    "## Table of Contents\n" +
    "\n" +
    "* Implements [GitHub Flavored Markdown](https://github.github.com/gfm/)\n" +
    '* Renders actual, "native" React DOM elements\n' +
    "* Allows you to escape or skip HTML (try toggling the checkboxes above)\n" +
    "* If you escape or skip the HTML, no `dangerouslySetInnerHTML` is used! Yay!\n" +
    "\n" +
    "## HTML block below\n" +
    "\n" +
    "<blockquote>\n" +
    "  This blockquote will change based on the HTML settings above.\n" +
    "</blockquote>\n" +
    "\n" +
    "## How about some code?\n" +
    "```js\n" +
    "var React = require('react');\n" +
    "var Markdown = require('react-markdown');\n" +
    "\n" +
    "React.render(\n" +
    '  <Markdown source="# Your markdown here" />,\n' +
    "  document.getElementById('content')\n" +
    ");\n" +
    "```\n" +
    "\n" +
    "Pretty neat, eh?\n" +
    "\n" +
    "## Tables?\n" +
    "\n" +
    "| Feature   | Support |\n" +
    "| --------- | ------- |\n" +
    "| tables    | ✔ |\n" +
    "| alignment | ✔ |\n" +
    "| wewt      | ✔ |\n" +
    "\n" +
    "## More info?\n" +
    "\n" +
    "Read usage information and more on [GitHub](//github.com/rexxars/react-markdown)\n" +
    "\n" +
    "---------------\n" +
    "\n" +
    "A component by [Espen Hovlandsdal](https://espen.codes/)\n";

function MarkdownDemo(_props: {}): JSX.Element {
    const [source, setSource] = useState("[foo](http://example.com)");

    return <div className={theme.demo}>
        <div className={theme.editor}>
            <textarea
                className={theme.editorArea}
                value={source}
                onChange={e => setSource(e.target.value)}
            />
        </div>
        <div className={theme.display}>
            <div className={theme.markdownBlock}>
                {renderMarkdown(source)}
            </div>
        </div>
    </div>;
}

export {
    renderMarkdown,
    MarkdownDemo,
    DEMO_SOURCE,
};
