import * as React from "react";

import { CardFooter, FormTitle, FormSubtitle, Button } from "@impero-com/ui";

import FormCardContainer from "components/formCardContainer";
import { QueryClientProvider } from "components/queryClient";
import RightSideIcon from "components/rightSideIcon";
import linkBrokenSvg from "img/link-broken.svg";
import { withHeader, Meta } from "utils/header";
import withI18n from "utils/i18n";


import { getSharedErrorProps, getErrorPageTitle } from "./sharedError";

const AnonymousError = (props: AnonymousErrorProps) => {
    return (
        <FormCardContainer rightSide={<RightSideIcon icon={props.icon ? props.icon : linkBrokenSvg} customImages={false} />}>
            <FormTitle title={props.title} />
            <FormSubtitle subtitle={props.subtitle} />
            <CardFooter>
                <Button
                    id="anonymous-error-button"
                    type="link"
                    onClick={props.action.handler}>
                    {props.action.label}
                </Button>
            </CardFooter>
        </FormCardContainer>
    );
};

interface AnonymousErrorProps {
    title: string,
    subtitle: string,
    icon?: any,
    action: {
        label: string,
        handler: () => void,
    },
}

export const handleAnonymousError = (code: number|undefined) => {
    const sharedProps = getSharedErrorProps(code);
    return <AnonymousError {...sharedProps} />;
};

class AnonymousErrorPageContent extends React.PureComponent<AnonymousErrorPageContentProps> {

    render = () => {
        const { title, children } = this.props;
        return (
            <>
                <Meta title={title} />
                {children}
            </>
        );
    };
}

interface AnonymousErrorPageContentProps {
    title: string,
    children: React.ReactNode,
}


export const AnonymousErrorPage = withI18n<AnonymousErrorPageProps>(withHeader<AnonymousErrorPageProps>(({ code }: AnonymousErrorPageProps) => {
    const title = getErrorPageTitle(code);
    return (
        <QueryClientProvider>
            <AnonymousErrorPageContent
                title={title}
            >
                {handleAnonymousError(code)}
            </AnonymousErrorPageContent>
        </QueryClientProvider>
    );
}));

interface AnonymousErrorPageProps {
    code: number | undefined,
}
