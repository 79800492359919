import * as React from "react";

import { CardFooter, FormSubtitle, FormTitle, Button } from "@impero-com/ui";

import FormCardContainer from "components/formCardContainer";
import { QueryClientProvider } from "components/queryClient";
import { Body } from "org/body";
import { withHeader } from "utils/header";
import withI18n from "utils/i18n";

import { getSharedErrorProps, getErrorPageTitle } from "./sharedError";

const LoggedInError = (props: LoggedInErrorProps) => {
    return (
        <FormCardContainer fullWidth={true}>
            <FormTitle title={props.title} />
            <FormSubtitle subtitle={props.subtitle} />
            <CardFooter>
                <Button
                    id="logged-in-error-button"
                    type="link"
                    onClick={props.action.handler}>
                    {props.action.label}
                </Button>
            </CardFooter>
        </FormCardContainer>
    );
};

interface LoggedInErrorProps {
    title: string,
    subtitle: string,
    action: {
        label: string,
        handler: () => void,
    },
}

interface LoggedInHttpErrorProps {
    code: number | undefined,
}
export const LoggedInHTTPError = ({
    code,
}: LoggedInHttpErrorProps) => {
    const sharedProps = getSharedErrorProps(code);
    return <LoggedInError {...sharedProps} />;
};

class LoggedInErrorPageContent extends React.PureComponent<LoggedInErrorPageContentProps> {
    render = () => {
        const { title, children } = this.props;
        return (
            <Body title={title} routerLinks={false} defaultAppBar>
                {children}
            </Body>
        );
    };
}

interface LoggedInErrorPageContentProps {
    title: string,
    children: React.ReactNode,
}

export const LoggedInErrorPage = withI18n<LoggedInErrorPageProps>(withHeader<LoggedInErrorPageProps>(({ code }: LoggedInErrorPageProps) => {
    const title = getErrorPageTitle(code);

    return (
        <QueryClientProvider>
            <LoggedInErrorPageContent
                title={title}
            >
                <LoggedInHTTPError code={code} />
            </LoggedInErrorPageContent>
        </QueryClientProvider>
    );
}));

interface LoggedInErrorPageProps {
    code: number | undefined,
}
